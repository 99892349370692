import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import SalesIcon from "@mui/icons-material/Store";
import SuppliersIcon from "@mui/icons-material/LocalShipping";
import UsersIcon from "@mui/icons-material/People";
import POsIcon from "@mui/icons-material/Description";
import PriceListIcon from "@mui/icons-material/Assessment";
import styled from "@emotion/styled";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ConfigIcon from "@mui/icons-material/ConnectWithoutContact";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import "../styles/sidebar.css";

const DrawerContainerBase = styled(Drawer)((props) => ({
  width: props.width,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: props.width,
    transition: "width 0.3s",
  },
}));

const DrawerContainer = ({ showLabels, ...rest }) => {
  const width = showLabels ? "240px" : "72px";
  return <DrawerContainerBase width={width} {...rest} />;
};

const ToggleButton = styled(IconButton)({
  position: "relative",
  top: "5px",
  right: "5px",
});

const Sidebar = ({ onPageChange, onToggleSidebar }) => {
  const [showLabels, setShowLabels] = useState(true);
  const [activeItem, setActiveItem] = useState(null); // Added state for active item
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setShowLabels(!showLabels);
    onToggleSidebar(!showLabels); // Report state back to parent
  };

  const categories = [
    {
      icon: <HomeIcon />,
      text: "Home",
      path: "/",
      subCategories: [
        { text: "Dashboard", path: "Home/Home" },
        { text: "Changelog", path: "Home/Changelog" },
      ],
    },
    {
      icon: <SalesIcon />,
      text: "Sales",
      path: "/Sales",
      subCategories: [
        { text: "Sales details", path: "Sales/SalesDetails" },
        //{ text: "Restock Advisor", path: "Sales/RestockAdvisor" },
        { text: "Inventory Planning", path: "Sales/InventoryPlanning" },
      ],
    },
    {
      icon: <SuppliersIcon />,
      text: "Suppliers",
      path: "/Suppliers",
      subCategories: [
        { text: "View Suppliers", path: "Suppliers/Suppliers" },
        { text: "Create Supplier", path: "Suppliers/CreateSupplier" },
      ],
    },
    {
      icon: <UsersIcon />,
      text: "Users",
      path: "/Users",
      subCategories: [{ text: "View Users", path: "Users/ViewUsers" }],
    },
    {
      icon: <ListAltIcon />,
      text: "Items",
      path: "/Items",
      subCategories: [
        { text: "View Items", path: "Items/ViewItems" },
        //{ text: "Add Item", path: "Items/AddItem" },
      ],
    },
    {
      icon: <POsIcon />,
      text: "POs",
      path: "/POs",
      subCategories: [
        { text: "View POs", path: "POs/ViewPOs" },
        { text: "Create / Upload PO", path: "POs/CreateUploadPO" },
      ],
    },
    {
      icon: <PriceListIcon />,
      text: "PriceList",
      path: "/PriceList",
      subCategories: [
        { text: "Add new Pricelist", path: "PriceListV2/PricelistPage" },
        {
          text: "View Current Pricelists",
          path: "PriceListV2/ViewCurrentPricelists",
        },
        //{ text: "Pricelist", path: "PriceListV2/PricelistPage" },
      ],
    },
    {
      icon: <AccountBalanceIcon />,
      text: "Accounting",
      path: "/Accounting",
      subCategories: [
        //{ text: "Accounts", path: "Accounting/Accounting" },
        //{ text: "Suppliers", path: "Accounting/Suppliers" },
        //{ text: "Employees", path: "Accounting/Employees" },
        { text: "Removals", path: "Accounting/Removals" },
        { text: "Expenses", path: "Accounting/Expenses" },
        { text: "Adjustments", path: "Accounting/Adjustments" },
      ],
    },
    {
      icon: <ConfigIcon />,
      text: "Manual Config",
      path: "/ManualConfig",
      subCategories: [
        { text: "Storage Override", path: "ManualConfig/ManualConfig" },
      ],
    },
  ];

  const handleMainCategoryClick = (index) => {
    if (activeItem === index) {
      setActiveItem(null);
    } else {
      setActiveItem(index);
    }
  };

  const handleSubCategoryClick = (path) => {
    navigate(path);
    onPageChange(path);
  };

  return (
    <DrawerContainer variant="permanent" showLabels={showLabels}>
      <ToggleButton onClick={toggleSidebar}>
        <MenuIcon />
      </ToggleButton>
      <List>
        {categories.map((item, index) => (
          <>
            <ListItem
              button
              key={index}
              onClick={() => handleMainCategoryClick(index)}
            >
              <Tooltip title={item.text} placement="right">
                <ListItemIcon>{item.icon}</ListItemIcon>
              </Tooltip>
              {showLabels && <ListItemText primary={item.text} />}
            </ListItem>
            <div
              className={`subCategoryList ${
                index === activeItem ? "active" : ""
              }`}
            >
              {item.subCategories.map((sub, subIndex) => (
                <Tooltip title={sub.text} key={subIndex} placement="right">
                  <ListItem
                    button
                    style={{ marginLeft: showLabels ? 20 : 0 }} // Adjust marginLeft based on showLabels
                    onClick={() => handleSubCategoryClick(sub.path)}
                  >
                    {showLabels && <ListItemText primary={sub.text} />}
                  </ListItem>
                </Tooltip>
              ))}
            </div>
          </>
        ))}
      </List>
    </DrawerContainer>
  );
};

export default Sidebar;
